<template>
  <b-row>
    <b-col cols="10">
      <b-form-group>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="icons-search"
            v-model="seachQuery"
            placeholder="Buscar Puntos de Iluminación..."
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-icon"
        @click="seachData()"
      >
        <feather-icon icon="SearchIcon" />
      </b-button>
    </b-col>
    <b-col
      v-for="(punto) in items"
      :key="punto.id"
      cols="12"
    >
      <b-card-group class="mb-0">
        <b-card
          :title="`Punto de Iluminación`"
          class="text-center"
          footer-class="text-muted"
        >
          <b-card-body>
            <b-row>
              <b-col cols="4">
                <h5>No. Poste:</h5>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  id="orden"
                  :value="punto.numeroPoste"
                  readonly
                  style="margin-top: -8px;"
                />
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px;">
              <b-col cols="4">
                <h5>Serie Lampara:</h5>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  id="orden"
                  :value="punto.serieLampara"
                  readonly
                />
              </b-col>
            </b-row>
            <b-row style="margin-top: 5px;">
              <b-col cols="4">
                <h5>Número Contador:</h5>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  id="orden"
                  :value="punto.numeroContador"
                  readonly
                />
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px;">
              <b-col cols="4">
                <h5>Potencia: </h5>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  id="orden"
                  :value="punto.potencia"
                  readonly
                  style="margin-top: -8px;"
                />
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px;">
              <b-col cols="6">
                <modal-detail-poste :id="punto.id" />
              </b-col>
              <b-col cols="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-secondary"
                  block
                  @click="selectPunto(punto)"
                >
                  Seleccionar Punto
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-card-group>
      <br>
    </b-col>
  </b-row>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BCardGroup,
  VBModal,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getPostesFilter } from '@/utils/postes'
import ModalDetailPoste from '@/components/PuntosIluminacion/ModalDetailPoste.vue'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BCardGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BCardBody,
    ModalDetailPoste,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      seachQuery: '',
      items: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      poste: null,
      origen: router.currentRoute.params.tipo,
      id: router.currentRoute.params.id,
    }
  },
  methods: {
    async seachData() {
      if (this.seachQuery === '') {
        this.error('Ingrese un valor de referencia del poste, serie o contador!')
        return
      }
      const filter = {
        where: {
          and: [
            { company: this.userData.idCompany },
            {
              or: [
                { numeroPoste: { regexp: this.seachQuery } },
                { serieLampara: { regexp: this.seachQuery } },
                { numeroContador: { regexp: this.seachQuery } },
              ],
            },
          ],
        },
      }
      this.items = await getPostesFilter(filter)
    },
    showDetalle(poste) {
      this.poste = poste
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    calcularFecha(fechaString) {
      const date = new Date(fechaString)
      date.setHours(date.getHours() + 6)
      return date.toLocaleString('es-GT')
    },
    selectPunto(punto) {
      this.$emit('update-origen', punto)
    },
  },
}
</script>
<style>
.carousel-item{
  top: 10%;
  right: 0;
  left: 0;
}
.carousel-caption{
  right: 0;
  left: 0;
}
</style>
