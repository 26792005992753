<template>
  <b-overlay
    :show="show"
  >
    <div>
      <b-tabs>
        <b-tab @click="ampliacion.puntoExistente = false">
          <template #title>
            <feather-icon icon="FilePlusIcon" />
            <span>Ampliación Nueva</span>
          </template>
          <validation-observer ref="formularioAmpliaciones1">
            <b-form
              ref="form"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >
              <b-row>
                <!-- Número de Poste -->
                <b-col :cols="!isMobile?6:12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="No. Poste"
                      rules="required"
                    >
                      <label>Número de Poste</label>
                      <b-form-input
                        id="numeroPoste"
                        v-model="ampliacion.poste"
                        type="text"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Foto -->
                <b-col
                  v-if="!isMobile"
                  cols="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Imagen de Ubicación"
                      rules="required"
                    >
                      <label>Imagen de Ubicación</label>
                      <b-form-file
                        id="wildcard"
                        v-model="imagen"
                        accept="image/*"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Encargado
                <b-col :cols="!isMobile?5:12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Encargado"
                      rules="required"
                    >
                      <label>Asignar Encargado</label>
                      <v-select
                        v-model="ampliacion.usuario"
                        label="nombre"
                        :options="usuarios"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                 -->
                <!--Municipio-->
                <b-col :cols="!isMobile?4:12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Municipio"
                      rules="required"
                    >
                      <label>Municipio</label>
                      <v-select
                        v-model="ampliacion.municipio"
                        label="nombre"
                        :options="municipios"
                        :state="errors.length > 0 ? false:null"
                        @input="recargarLocalidades"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--Localidad-->
                <b-col :cols="!isMobile?8:12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Localidad"
                      rules="required"
                    >
                      <label>Localidad / Población</label>
                      <v-select
                        v-model="ampliacion.localidad"
                        label="name"
                        :options="localidades"
                        :state="errors.length > 0 ? false:null"
                        @input="recargarZona"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Calle / Avenida -->
                <b-col :cols="!isMobile?6:7">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Calle / Avenida"
                      rules="required"
                    >
                      <label>Calle / Avenida</label>
                      <b-form-input
                        id="calleAveniada"
                        v-model="ampliacion.calleAvenida"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Numeral -->
                <b-col :cols="!isMobile?6:5">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Numeral"
                      rules="required"
                    >
                      <label>Numeral</label>
                      <b-form-input
                        id="numeral"
                        v-model="ampliacion.numeral"
                        type="text"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group />
                </b-col>
              </b-row>
              <b-row>
                <!-- Latitud -->
                <b-col
                  v-if="!isMobile"
                  cols="6"
                >
                  <b-form-group>
                    <label>Latitud</label>
                    <b-form-input
                      id="basicInput"
                      v-model="ampliacion.latitud"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <!-- Longitud -->
                <b-col
                  v-if="!isMobile"
                  cols="6"
                >
                  <b-form-group>
                    <label>Longitud</label>
                    <b-form-input
                      id="basicInput"
                      v-model="ampliacion.longitud"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <!-- Componente imagen -->
                <b-col
                  v-if="isMobile"
                  cols="12"
                >
                  <h4>Ubicación de Instalación</h4>
                  <center>
                    <imagen
                      :key="'falla'"
                      :leyenda="'falla'"
                      @cargar-file="imagenSeleccionada"
                    />
                  </center>
                </b-col>
              </b-row>
              <!-- Comentarios -->
              <b-row>
                <b-col :cols="!isMobile?12:12">
                  <label for="comentarios1">Comentarios</label>
                  <b-form-textarea
                    id="comentarios"
                    v-model="ampliacion.comentarios"
                    no-resize
                  />
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col
                  :cols="!isMobile?12:12"
                >
                  <table style="width: 100%">
                    <tr>
                      <td
                        v-if="metersOfCable"
                        style="width: 40%"
                      >
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Metro de 240V"
                            rules="required"
                          >
                            <label>Metros de Linea 240V</label>
                            <b-form-input
                              id="metroLinea"
                              v-model.number="ampliacion.metros"
                              type="number"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </td>
                      <td
                        v-if="!metersOfCable"
                        style="width:70%"
                      >
                    &nbsp;  &nbsp;
                      </td>
                      <td
                        v-if="metersOfCable"
                        style="width:20%"
                      >
                        &nbsp;  &nbsp;
                      </td>
                      <td>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          @click="repeateAgain"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>Agregar Accesorios</span>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <!--Equipos Varios-->
              <b-row>
                <b-col cols="12">
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in ampliacion.items"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >

                    <!-- Tipo -->
                    <b-col
                      v-if="isMobile"
                    >
                      <hr class="Linea">
                    </b-col>
                    <b-col :cols="!isMobile?3:12">
                      <validation-provider
                        #default="{ errors }"
                        name="Item name"
                        rules="required"
                      >
                        <b-form-group
                          label="Tipo"
                          :label-for="`item-name${item.id}`"
                        >
                          <v-select
                            id="`item-name${item.id}`"
                            v-model="item.tipo"
                            label="nombre"
                            :options="listadoSelect"
                            :state="errors.length > 0 ? false:null"
                            required
                            :disabled="isEnabledSelect(index,item)"
                            @input="changueListado(item.tipo, index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Accesorio -->
                    <b-col :cols="!isMobile?8:12">
                      <validation-provider
                        #default="{ errors }"
                        name="Accesorio"
                        rules="required"
                      >
                        <label>Accesorios</label>
                        <b-form-group>
                          <v-select
                            v-model="item.item"
                            label="nombre"
                            :options="item.options"
                            :disabled="isEnabledSelect(index,item)"
                            :state="errors.length > 0 ? false:null"
                            required
                            @input="validarMetrosCable(item.item)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col :cols="!isMobile?1:12">
                      <b-button
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        style="margin-left: -15px; margin-top: 5px; width: 10px; float: right;"
                        :disabled="isEnabledSelect(index,item)"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-10"
                          style="margin-left: -7px;"
                        />
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="isMobile"
                    >
                      <hr class="Linea">
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <br>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="btn btn-primary float-right"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Guardar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-tab>
        <b-tab @click="ampliacion.puntoExistente = true">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>Ampliación Punto Existente</span>
          </template>
          <b-card
            no-body
            class="mb-0"
          >
            <validation-observer ref="formularioAmpliaciones2">
              <div v-if="!showInformation">
                <h4>Búsqueda de Punto Existente</h4>
                <b-row>
                  <b-col cols="12">
                    <search-punto @update-origen="selectPunto" />
                  </b-col>
                </b-row>
              </div>
              <b-form
                v-if="showInformation"
                ref="form"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <b-row>
                  <!-- Número de Poste -->
                  <b-col :cols="!isMobile?6:12">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="No. Poste"
                        rules="required"
                      >
                        <label>Número de Poste</label>
                        <b-form-input
                          id="numeroPoste"
                          v-model="ampliacion.poste"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          disabled="disabled"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Foto -->
                  <b-col
                    v-if="!isMobile"
                    cols="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Imagen de Ubicación"
                        rules="required"
                      >
                        <label>Imagen de Ubicación</label>
                        <b-form-file
                          id="wildcard"
                          v-model="imagen"
                          accept="image/*"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Encargado
                <b-col :cols="!isMobile?5:12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Encargado"
                      rules="required"
                    >
                      <label>Asignar Encargado</label>
                      <v-select
                        v-model="ampliacion.usuario"
                        label="nombre"
                        :options="usuarios"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                 -->
                  <!--Municipio-->
                  <b-col :cols="!isMobile?4:12">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Municipio"
                        rules="required"
                      >
                        <label>Municipio</label>
                        <v-select
                          v-model="ampliacion.municipio"
                          label="nombre"
                          :options="municipios"
                          :state="errors.length > 0 ? false:null"
                          @input="recargarLocalidades"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--Localidad-->
                  <b-col :cols="!isMobile?8:12">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Localidad"
                        rules="required"
                      >
                        <label>Localidad / Población</label>
                        <v-select
                          v-model="ampliacion.localidad"
                          label="name"
                          :options="localidades"
                          :state="errors.length > 0 ? false:null"
                          @input="recargarZona"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Calle / Avenida -->
                  <b-col :cols="!isMobile?6:7">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Calle / Avenida"
                        rules="required"
                      >
                        <label>Calle / Avenida</label>
                        <b-form-input
                          id="calleAveniada"
                          v-model="ampliacion.calleAvenida"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Numeral -->
                  <b-col :cols="!isMobile?6:5">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Numeral"
                        rules="required"
                      >
                        <label>Numeral</label>
                        <b-form-input
                          id="numeral"
                          v-model="ampliacion.numeral"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Latitud -->
                  <b-col
                    v-if="!isMobile"
                    cols="6"
                  >
                    <b-form-group>
                      <label>Latitud</label>
                      <b-form-input
                        id="basicInput"
                        v-model="ampliacion.latitud"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Longitud -->
                  <b-col
                    v-if="!isMobile"
                    cols="6"
                  >
                    <b-form-group>
                      <label>Longitud</label>
                      <b-form-input
                        id="basicInput"
                        v-model="ampliacion.longitud"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Componente imagen -->
                  <b-col
                    v-if="isMobile"
                    cols="12"
                  >
                    <h4>Ubicación de Instalación</h4>
                    <center>
                      <imagen
                        :key="'falla'"
                        :leyenda="'falla'"
                        @cargar-file="imagenSeleccionada"
                      />
                    </center>
                  </b-col>
                </b-row>
                <!-- Comentarios -->
                <b-row>
                  <b-col :cols="!isMobile?12:12">
                    <label for="comentarios1">Comentarios</label>
                    <b-form-textarea
                      id="comentarios"
                      v-model="ampliacion.comentarios"
                      no-resize
                    />
                  </b-col>
                </b-row>
                <br>
                <b-row>
                  <b-col :cols="!isMobile?12:12">
                    <table style="width: 100%">
                      <tr>
                        <td
                          v-if="metersOfCable"
                          style="width: 40%"
                        >
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="Metro de 240V"
                              rules="required"
                            >
                              <label>Metros de Linea 240V</label>
                              <b-form-input
                                id="metroLinea"
                                v-model.number="ampliacion.metros"
                                type="number"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </td>
                        <td
                          v-if="!metersOfCable"
                          style="width:70%"
                        >
                        &nbsp;  &nbsp;
                        </td>
                        <td
                          v-if="metersOfCable"
                          style="width:20%"
                        >
                        &nbsp;  &nbsp;
                        </td>
                        <td>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            @click="repeateAgain"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span>Agregar Accesorios</span>
                          </b-button>
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
                <!--Equipos Varios-->
                <b-row>
                  <b-col cols="12">
                    <!-- Row Loop -->
                    <b-row
                      v-for="(item, index) in ampliacion.items"
                      :id="item.id"
                      :key="item.id"
                      ref="row"
                    >

                      <!-- Tipo -->
                      <b-col
                        v-if="isMobile"
                      >
                        <hr class="Linea">
                      </b-col>
                      <b-col :cols="!isMobile?3:12">
                        <validation-provider
                          #default="{ errors }"
                          name="Item name"
                          rules="required"
                        >
                          <b-form-group
                            label="Tipo"
                            :label-for="`item-name${item.id}`"
                          >
                            <v-select
                              id="`item-name${item.id}`"
                              v-model="item.tipo"
                              label="nombre"
                              :options="listadoSelect"
                              :state="errors.length > 0 ? false:null"
                              :disabled="index===0 && item.item.id === 2 && item.tipo.id === 10"
                              required
                              @input="changueListado(item.tipo, index)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Accesorio -->
                      <b-col :cols="!isMobile?8:12">
                        <validation-provider
                          #default="{ errors }"
                          name="Accesorio"
                          rules="required"
                        >
                          <label>Accesorios</label>
                          <b-form-group>
                            <v-select
                              v-model="item.item"
                              label="nombre"
                              :options="item.options"
                              :state="errors.length > 0 ? false:null"
                              :disabled="index===0 && item.item.id === 2 && item.tipo.id === 10"
                              required
                              @input="validarMetrosCable(item.item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col :cols="!isMobile?1:12">
                        <b-button
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          style="margin-left: -15px; margin-top: 5px; width: 10px; float: right;"
                          :disabled="index===0 && item.item.id === 2 && item.tipo.id === 10"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-10"
                            style="margin-left: -7px;"
                          />
                        </b-button>
                      </b-col>
                      <b-col
                        v-if="isMobile"
                      >
                        <hr class="Linea">
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12">
                    <br>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      class="btn btn-primary float-right"
                      variant="primary"
                      type="submit"
                      @click.prevent="validationForm"
                    >
                      Guardar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BFormTextarea, BCard, BForm, BFormFile, BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { getLocalidades, getMunicipio } from '@/utils/localizacion'
import { getItem, getlistado } from '@/utils/catalogos'
import { isNumber } from '@vueuse/core'
import { updateCreateAmpliacion } from '@/utils/inspecciones'
import { getUsersByCompany } from '@/utils/usuarios'
import { sendFile } from '@/utils/files'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import SearchPunto from '@/components/PuntosIluminacion/SearchPunto.vue'

export default {

  components: {
    SearchPunto,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BCard,
    BForm,
    BFormFile,
    BFormInput,
    Imagen,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    ampliacion: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      showInformation: false,
      imagen: null,
      localization: null,
      metersOfCable: false,
      listadoSelect: [
        {
          id: 9,
          catalogo: 'tipo_lampara',
          nombre: 'Lámpara',
        },
        {
          id: 1,
          catalogo: 'tipo_poste',
          nombre: 'Poste',
        },
        {
          id: 14,
          catalogo: 'tipo_brazo',
          nombre: 'Brazo',
        },
        {
          id: 10,
          catalogo: 'unidades_varias',
          nombre: 'Otros',
        },
      ],
      municipios: [],
      localidades: [],
      usuarios: [],
      required,
      nextTodoId: 2,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.initTrHeight()
    this.validarMetros()
  },
  beforeDestroy() {
    this.showInformation = false
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  async beforeMount() {
    await this.loadCatalogos()
    this.usuarios = await getUsersByCompany(this.usuario.idCompany) // getUsersByCompanyRole(this.usuario.idCompany, 'inspecciones')
    this.usuarios.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
  },
  methods: {
    validarMetros() {
      this.ampliacion.items.forEach(item => {
        if (!this.metersOfCable) this.validarMetrosCable(item.item)
      })
    },
    validarMetrosCable(item) {
      if (item) this.metersOfCable = item.nombre === 'Metros de Cable' || item.id === 0
    },
    isEnabledSelect(index, item) {
      if (index === 0 && item) {
        if (item.item) {
          return item.item.id === 2 && item.tipo.id === 10
        }
      }
      return false
    },
    async selectPunto(punto) {
      this.ampliacion.punto = punto
      this.showInformation = true
      this.ampliacion.poste = punto.numeroPoste
      this.ampliacion.municipio = await getMunicipio(punto.municipio)
      // eslint-disable-next-line prefer-destructuring
      this.ampliacion.localidad = this.localidades.filter(local => local.id === punto.localidad)[0]
      this.ampliacion.calleAvenida = punto.calleAvenida
      this.ampliacion.numeral = punto.numeral
      this.ampliacion.latitud = punto.latitud
      this.ampliacion.longitud = punto.longitud
      this.ampliacion.grupo = punto.objGrupo
      this.ampliacion.codigo = punto.serieLampara
      this.ampliacion.redComunicacion = punto.redComunicacion
    },
    async loadCatalogos() {
      // this.municipios = await getMunicipios(false)
      await this.loadLocalidades()
    },
    async changueListado(item, pos) {
      let catalogo = ''
      this.ampliacion.items[pos].item = null
      let listado = []
      if (item === null) {
        this.ampliacion.items[pos].options = []
        return
      }
      if (item.id === 9) catalogo = 'Tipos de Lamparas'
      if (item.id === 1) catalogo = 'Tipo Postes'
      if (item.id === 14) catalogo = 'Tipos de Brazos'
      if (item.id === 10) catalogo = 'Unidades Varias'

      if (catalogo !== '') listado = await getlistado(item.id, catalogo, false, this.usuario)
      this.ampliacion.items[pos].options = listado
    },
    async recargarLocalidades() {
      /* this.zona = ''
      this.ampliacion.localidad = null
      await this.loadLocalidades() */
    },
    recargarZona() {
      this.zona = ''
      this.zona = this.ampliacion.localidad !== null ? this.ampliacion.localidad.zona : ''
    },
    async loadLocalidades() {
      this.localidades = await getLocalidades(
        false,
        isNumber(this.ampliacion.municipio)
          ? this.ampliacion.municipio
          : this.ampliacion.municipio.id,
      )
    },
    validationForm() {
      this.$refs.formularioAmpliaciones1.validate()
      this.$refs.formularioAmpliaciones2.validate()
      if (this.imagen === null || this.imagen === undefined) {
        this.error('No se tiene una imagen cargada!')
        return
      }
      if (this.metersOfCable && this.ampliacion.metros === 0) {
        this.error('El valor de los Metros de Linea 240V debe ser mayor a 0!')
        return
      }

      let errors = false
      this.ampliacion.items.forEach(i => {
        if (!i.item) {
          this.error('No se tiene un item cargado!')
          errors = true
        }
      })

      if (errors) {
        this.error('Se deben de completar todos los accesorios!')
        return
      }

      this.show = true
      this.$refs.formularioAmpliaciones1.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          for (const item of this.ampliacion.items) {
            delete item.options
          }

          this.ampliacion.usuario = {
            id: this.usuario.id,
            nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            email: this.usuario.email,
          }

          if (this.isMobile) {
            this.ampliacion.latitud = this.localization ? `${this.localization.latitude}` : ''
            this.ampliacion.longitud = this.localization ? `${this.localization.longitude}` : ''
          }

          if (!this.ampliacion.id) {
            const resultado = await updateCreateAmpliacion(this.ampliacion, 2)
            if (resultado !== null) {
              this.ampliacion.id = resultado.id
              if (!(await this.saveImagen(resultado.id, true))) return
              this.success('Nueva Ampliación', 'Ampliación ingresada correctamente!')
              this.$emit('reload-ampliaciones')
              return
            }
          } else {
            this.ampliacion.estado = 'AUTORIZACION'
            if (this.ampliacion.fechaInstalacion === null) delete this.ampliacion.fechaInstalacion
            if (this.ampliacion.grupo === null) delete this.ampliacion.grupo
            const resultado = await updateCreateAmpliacion(this.ampliacion, 1)
            if (resultado !== null) {
              if (!(await this.saveImagen(this.ampliacion.id, false))) return
              this.success('Actualización Ampliación', 'Ampliación actualizada correctamente!')
              this.$emit('reload-ampliaciones')
              return
            }
          }
          this.error('Ocurrió un problema guardando la inspección, intente nuevamente!')
        }
      })
    },
    async saveImagen(id, isNew) {
      const urlBucket = `inspecciones/${id}`
      const file = await sendFile(this.imagen, 'images', urlBucket)
      if (file !== null) {
        const item = file.data
        item.isMobile = window.screen.width <= 760
        item.localization = this.localization
        // eslint-disable-next-line no-nested-ternary
        item.tipo = 'Ingreso de Ampliación'
        item.comentarios = ''
        item.fechaHora = new Date()
        item.usuario = {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        }
        if (!isNew) this.ampliacion.tracking[0] = item
        else this.ampliacion.tracking.push(item)
        await updateCreateAmpliacion(this.ampliacion, 1)
      }
      return true
    },
    cerrarDialog() {
      this.$emit('reload-ampliaciones')
    },
    repeateAgain() {
      this.ampliacion.items.push({
        id: this.nextTodoId += this.nextTodoId,
        options: [],
        tipo: null,
        item: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    imagenSeleccionada(file) {
      this.imagen = file
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    removeItem(index) {
      // Si el select no tiene items seleccionados se puede eliminar
      const item = this.ampliacion.items[index]
      if (item.item && item.options.length === 0) {
        this.ampliacion.items.splice(index, 1)
      } else {
        if (item.item && item.item.id === 0) {
          this.metersOfCable = false
          this.ampliacion.metros = 0
        }
        this.ampliacion.items.splice(index, 1)
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}

</script>

<style scoped>
.mb-0{
  padding: 15px;
  flex: 1 1 auto;
  min-height: 450px;
  box-sizing: content-box;
  border-radius: 10px;
}
hr.Linea {
  border: 0.5px solid #376FA8;
  border-radius: 3px;

}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
