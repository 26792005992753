<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 159, 67, 0.15)'"
      v-b-tooltip.hover.v-warning
      title="Descargar Reporte"
      variant="warning"
      class="btn-icon"
      @click="generarExcel(ampliacionesData)"
    >
      <feather-icon icon="DownloadIcon" />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import xl from 'excel4node'
import { getDatosInformeAmpliacion } from '@/utils/catalogos'
import { ordernarItemsAmpliacion } from '@/utils/files'
import { formatDate } from '@/utils/fechas'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    ampliacionesData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: [],
    }
  },
  methods: {
    recalcularTotal(item) {
      let total = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const row of item.items) {
        total += row.precio ? row.precio : 0
      }
      return total
    },
    async generarExcel(ampliacionesData) {
      // eslint-disable-next-line global-require,no-shadow
      const xl = require('excel4node')
      const wb = new xl.Workbook()
      const ws = wb.addWorksheet('Hoja 1')

      // Set value of cell A1 to 100 as a number type styled with paramaters of style
      const styleTitulo = wb.createStyle({
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleItems = wb.createStyle({
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          textRotation: 90,
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleDetalleCurrency2 = wb.createStyle({
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#1396e3',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
        numberFormat: 'Q #,##0.00',
      })
      const styleDetalleCurrency = wb.createStyle({
        font: {
          size: 10,
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
        numberFormat: 'Q #,##0.00',
      })
      const styleDetalle = wb.createStyle({
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#1396e3',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleCeldasColor = wb.createStyle({
        font: {
          size: 10,
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#92cddc',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleCeldas = wb.createStyle({
        font: {
          size: 10,
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })

      const suma = 1
      const totales = {
        item1: 0,
        item2: 0,
        item3: 0,
        item4: 0,
        item5: 0,
        item6: 0,
        item7: 0,
        item8: 0,
        item9: 0,
        item10: 0,
        item11: 0,
        item12: 0,
        item13: 0,
        item14: 0,
        item15: 0,
        item16: 0,
        item17: 0,
        item18: 0,
        item19: 0,
        item20: 0,
        item21: 0,
        item22: 0,
        totalCostos: 0,
        ampliaciones: [],
      }

      const listado = await getDatosInformeAmpliacion()

      // eslint-disable-next-line no-restricted-syntax
      for await (const ampliacion of ampliacionesData) {
        const items = await ordernarItemsAmpliacion({ ...ampliacion }, listado)
        totales.item1 += items.filter(i => i.correlativo === 1).length
        totales.item2 += items.filter(i => i.correlativo === 2).length
        totales.item3 += items.filter(i => i.correlativo === 3).length
        totales.item4 += items.filter(i => i.correlativo === 4).length
        totales.item5 += items.filter(i => i.correlativo === 5).length
        totales.item6 += items.filter(i => i.correlativo === 6).length
        totales.item7 += items.filter(i => i.correlativo === 7).length
        totales.item8 += items.filter(i => i.correlativo === 8).length
        totales.item9 += items.filter(i => i.correlativo === 9).length
        totales.item10 += items.filter(i => i.correlativo === 10).length
        totales.item11 += items.filter(i => i.correlativo === 11).length
        totales.item12 += ampliacion.metros
        totales.item13 += items.filter(i => i.correlativo === 13).length
        totales.item14 += items.filter(i => i.correlativo === 14).length
        totales.item15 += items.filter(i => i.correlativo === 15).length
        totales.item16 += items.filter(i => i.correlativo === 16).length
        totales.item17 += items.filter(i => i.correlativo === 17).length
        totales.item18 += items.filter(i => i.correlativo === 18).length
        totales.item19 += items.filter(i => i.correlativo === 19).length
        totales.item20 += items.filter(i => i.correlativo === 20).length
        totales.item21 += items.filter(i => i.correlativo === 21).length
        totales.ampliaciones.push({
          id: ampliacion.id,
          items,
        })
        totales.totalCostos += this.recalcularTotal(ampliacion)
      }

      ws.cell(suma, 10).string('Total Items').style(styleDetalle)
      ws.cell(suma, 11).number(totales.item1).style(styleDetalle)
      ws.cell(suma, 12).number(totales.item2).style(styleDetalle)
      ws.cell(suma, 13).number(totales.item3).style(styleDetalle)
      ws.cell(suma, 14).number(totales.item4).style(styleDetalle)
      ws.cell(suma, 15).number(totales.item5).style(styleDetalle)
      ws.cell(suma, 16).number(totales.item6).style(styleDetalle)
      ws.cell(suma, 17).number(totales.item7).style(styleDetalle)
      ws.cell(suma, 18).number(totales.item8).style(styleDetalle)
      ws.cell(suma, 19).number(totales.item9).style(styleDetalle)
      ws.cell(suma, 20).number(totales.item10).style(styleDetalle)
      ws.cell(suma, 21).number(totales.item11).style(styleDetalle)
      ws.cell(suma, 22).number(totales.item12).style(styleDetalle)
      ws.cell(suma, 23).number(totales.item13).style(styleDetalle)
      ws.cell(suma, 24).number(totales.item14).style(styleDetalle)
      ws.cell(suma, 25).number(totales.item15).style(styleDetalle)
      ws.cell(suma, 26).number(totales.item16).style(styleDetalle)
      ws.cell(suma, 27).number(totales.item17).style(styleDetalle)
      ws.cell(suma, 28).number(totales.item18).style(styleDetalle)
      ws.cell(suma, 29).number(totales.item19).style(styleDetalle)
      ws.cell(suma, 30).number(totales.item20).style(styleDetalle)
      ws.cell(suma, 31).string('').style(styleCeldas)
      ws.cell(suma, 32).number(totales.totalCostos).style(styleDetalleCurrency2)

      ws.cell(3, 1).string('No.').style(styleTitulo)
      ws.cell(3, 2).string('Poste No.').style(styleTitulo)
      ws.cell(3, 3).string('Zona').style(styleTitulo)
      ws.cell(3, 4).string('Dirección').style(styleTitulo)
      ws.cell(3, 5).string('Colonia').style(styleTitulo)
      ws.cell(3, 6).string('Coordenadas').style(styleTitulo)
      ws.cell(3, 7).string('Grupo').style(styleTitulo)
      ws.cell(3, 8).string('Observaciones').style(styleTitulo)
      ws.cell(3, 9).string('Fecha de Instalación').style(styleTitulo)
      ws.cell(3, 10).string('Código').style(styleTitulo)
      ws.cell(3, 11).string('1.Lámpara Tipo A').style(styleItems)
      ws.cell(3, 12).string('2.Lámpara Tipo B').style(styleItems)
      ws.cell(3, 13).string('3.Lámpara Tipo C').style(styleItems)
      ws.cell(3, 14).string('4.Lámpara Tipo E').style(styleItems)
      ws.cell(3, 15).string('5.Lámpara Tipo F').style(styleItems)
      ws.cell(3, 16).string('6.Lámpara Tipo G').style(styleItems)
      ws.cell(3, 17).string("7.Poste Concreto 30'").style(styleItems)
      ws.cell(3, 18).string("8.Poste Concreto 35'").style(styleItems)
      ws.cell(3, 19).string("9.Poste Concreto 40'").style(styleItems)
      ws.cell(3, 20).string("10.Poste metal seccionado 25'").style(styleItems)
      ws.cell(3, 21).string("11.Poste metal 35'").style(styleItems)
      ws.cell(3, 22).string('12.Metros de Cable').style(styleItems)
      ws.cell(3, 23).string("13.Brazo galvanizado 1(4')").style(styleItems)
      ws.cell(3, 24).string("14.Brazo galvanizado 2(6')").style(styleItems)
      ws.cell(3, 25).string("15.Brazo galvanizado 3(8')").style(styleItems)
      ws.cell(3, 26).string("16.Brazo galvanizado 4(12')").style(styleItems)
      ws.cell(3, 27).string('17.Bajada a Tierra Fisica').style(styleItems)
      ws.cell(3, 28).string('18.Montaje y configuración').style(styleItems)
      ws.cell(3, 29).string('19.MO Instalación Poste').style(styleItems)
      ws.cell(3, 30).string('20.Movimiento de poste').style(styleItems)
      ws.cell(3, 31).string('ESTATUS').style(styleItems)
      ws.cell(3, 32).string('COSTO DE INVERSION EN QUETZALES').style(styleItems)

      let contador = 4
      // eslint-disable-next-line no-restricted-syntax
      for await (const ampliacion of ampliacionesData) {
        const { items } = totales.ampliaciones.filter(a => a.id === ampliacion.id)[0]

        ws.cell(contador, 1).number(contador - 3).style(styleCeldas)
        ws.cell(contador, 2).string(ampliacion.poste).style(styleCeldasColor)
        ws.cell(contador, 3).string(ampliacion.localidad.zona).style(styleCeldas)
        ws.cell(contador, 4).string(ampliacion.calleAvenida).style(styleCeldas)
        ws.cell(contador, 5).string(ampliacion.localidad.nombre).style(styleCeldas)
        ws.cell(contador, 6).string(`${ampliacion.latitud} ${ampliacion.longitud}`).style(styleCeldas)
        ws.cell(contador, 7).string(ampliacion.grupo ? ampliacion.grupo.nombre : '').style(styleCeldasColor)
        ws.cell(contador, 8).string(ampliacion.comentarios).style(styleCeldasColor)
        if (ampliacion.fechaRealInstalacion) {
          ws.cell(contador, 9).string(formatDate(ampliacion.fechaRealInstalacion, '/')).style(styleCeldasColor)
        } else {
          ws.cell(contador, 9).string('').style(styleCeldasColor)
        }
        ws.cell(contador, 10).string(ampliacion.codigo).style(styleCeldasColor)
        ws.cell(contador, 11).number(items.filter(i => i.correlativo === 1).length).style(styleCeldasColor)
        ws.cell(contador, 12).number(items.filter(i => i.correlativo === 2).length).style(styleCeldasColor)
        ws.cell(contador, 13).number(items.filter(i => i.correlativo === 3).length).style(styleCeldasColor)
        ws.cell(contador, 14).number(items.filter(i => i.correlativo === 4).length).style(styleCeldasColor)
        ws.cell(contador, 15).number(items.filter(i => i.correlativo === 5).length).style(styleCeldasColor)
        ws.cell(contador, 16).number(items.filter(i => i.correlativo === 6).length).style(styleCeldasColor)
        ws.cell(contador, 17).number(items.filter(i => i.correlativo === 7).length).style(styleCeldasColor)
        ws.cell(contador, 18).number(items.filter(i => i.correlativo === 8).length).style(styleCeldasColor)
        ws.cell(contador, 19).number(items.filter(i => i.correlativo === 9).length).style(styleCeldasColor)
        ws.cell(contador, 20).number(items.filter(i => i.correlativo === 10).length).style(styleCeldasColor)
        ws.cell(contador, 21).number(items.filter(i => i.correlativo === 11).length).style(styleCeldasColor)
        ws.cell(contador, 22).number(ampliacion.metros).style(styleCeldasColor)
        ws.cell(contador, 23).number(items.filter(i => i.correlativo === 13).length).style(styleCeldasColor)
        ws.cell(contador, 24).number(items.filter(i => i.correlativo === 14).length).style(styleCeldasColor)
        ws.cell(contador, 25).number(items.filter(i => i.correlativo === 15).length).style(styleCeldasColor)
        ws.cell(contador, 26).number(items.filter(i => i.correlativo === 16).length).style(styleCeldasColor)
        ws.cell(contador, 27).number(items.filter(i => i.correlativo === 17).length).style(styleCeldasColor)
        ws.cell(contador, 28).number(items.filter(i => i.correlativo === 18).length).style(styleCeldasColor)
        ws.cell(contador, 29).number(items.filter(i => i.correlativo === 19).length).style(styleCeldasColor)
        ws.cell(contador, 30).number(items.filter(i => i.correlativo === 20).length).style(styleCeldasColor)
        ws.cell(contador, 31).string(ampliacion.fechaRealInstalacion ? 'OK' : '').style(styleCeldasColor)

        const total = this.recalcularTotal(ampliacion)
        ws.cell(contador, 32).number(total).style(styleDetalleCurrency)
        contador += 1
      }

      ws.column(1).setWidth(5)
      ws.column(2).setWidth(10)
      ws.column(3).setWidth(5)
      ws.column(4).setWidth(30)
      ws.column(5).setWidth(20)
      ws.column(6).setWidth(30)
      ws.column(7).setWidth(8)
      ws.column(8).setWidth(30)
      ws.column(9).setWidth(10)
      ws.column(10).setWidth(10)
      ws.column(11).setWidth(5)
      ws.column(12).setWidth(5)
      ws.column(13).setWidth(5)
      ws.column(14).setWidth(5)
      ws.column(15).setWidth(5)
      ws.column(16).setWidth(5)
      ws.column(17).setWidth(5)
      ws.column(18).setWidth(5)
      ws.column(19).setWidth(5)
      ws.column(20).setWidth(5)
      ws.column(21).setWidth(5)
      ws.column(22).setWidth(5)
      ws.column(23).setWidth(5)
      ws.column(24).setWidth(5)
      ws.column(25).setWidth(5)
      ws.column(26).setWidth(5)
      ws.column(27).setWidth(5)
      ws.column(28).setWidth(5)
      ws.column(29).setWidth(5)
      ws.column(30).setWidth(5)
      ws.column(31).setWidth(5)
      ws.column(32).setWidth(15)

      ws.row(3).setHeight(140)

      for (let i = 4; i <= 8; i++) {
        ws.row(i).setHeight(25)
      }

      const buffer = await wb.writeToBuffer()
      const string = buffer.toString('base64')
      const linkSource = `data:application/xlsx;base64,${string}`
      const downloadLink = document.createElement('a')
      const fileName = 'Reporte Ampliaciones.xlsx'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  },
}
</script>

<style scoped>

</style>
